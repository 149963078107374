<template>
  <div v-if="currentUser">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-[#1C1C1C] px-6 pb-2">
                <div class="flex h-16 shrink-0 items-center gap-2">
                  <img class="h-8 w-auto" :src="logoUrl" alt="Your Company" />
                  <div class="flex flex-col">
                    <span class="text-sm font-semibold leading-6 text-white">{{ team?.name }}</span>
                    <span class="text-xs text-gray-400">Web Solutions</span>
                  </div>
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <Link @click="sidebarOpen = false" :href="item.href" :class="[isCurrentPath(item.href) ? 'bg-[#2C2C2C] text-white' : 'text-gray-400 hover:bg-[#2C2C2C] hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']">
                            <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                            {{ item.name }}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-[#1C1C1C] px-6">
        <div class="flex h-16 shrink-0 items-center gap-2">
          <img class="h-8 w-auto" :src="logoUrl" alt="Your Company" />
          <div class="flex flex-col">
            <span class="text-sm font-semibold leading-6 text-white">{{ team?.name }}</span>
          </div>
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <Link :href="item.href" :class="[isCurrentPath(item.href) ? 'bg-[#2C2C2C] text-white' : 'text-gray-400 hover:bg-[#2C2C2C] hover:text-white', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']">
                    <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                    {{ item.name }}
                  </Link>
                </li>
              </ul>
            </li>
            <li class="-mx-6 mt-auto">
              <Link href="/ustawienia" class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-[#2C2C2C]">
                <img class="h-8 w-8 rounded-full bg-[#2C2C2C]" :src="userLogoUrl" alt="" />
                <span aria-hidden="true">{{ currentUser?.first_name }} {{ currentUser?.last_name }}</span>
              </Link>
              <form @submit.prevent="logout" class="px-6 py-3">
                <button type="submit" class="text-sm font-semibold leading-6 text-gray-400 hover:text-white hover:bg-[#2C2C2C] w-full text-left py-2 px-3 rounded-md">
                  Wyloguj się
                </button>
              </form>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="sticky top-0 z-40 flex items-center gap-x-6 bg-[#1C1C1C] px-4 py-4 shadow-sm sm:px-6 lg:hidden">
      <button type="button" class="-m-2.5 p-2.5 text-gray-400 lg:hidden" @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm font-semibold leading-6 text-white" />
      <Link @click="sidebarOpen = false" href="/ustawienia">
        <span class="sr-only">Profil</span>
        <img class="h-8 w-8 rounded-full bg-[#2C2C2C]" :src="userLogoUrl" alt="" />
      </Link>
    </div>

    <main class="py-4 sm:py-6 lg:py-8 lg:pl-72">
      <div class="px-4 sm:px-6 lg:px-8">
        <slot />
      </div>
    </main>
  </div>
  <div v-else >
    <slot />
  </div>
  <ConfirmDialog />
  <Toast />
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { Link, usePage, router } from '@inertiajs/vue3'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/vue/24/outline'
import { CurrentUser, Team } from '@/global'
import logoPlaceholder from '@/assets/logo-placeholder.png'

const props = defineProps<{
  currentUser: CurrentUser
  team: Team
}>()

const page = usePage()

const isCurrentPath = (path: string) => {
  return page.url === path
}

const logoUrl = computed(() => props.currentUser?.logo || logoPlaceholder)
const userLogoUrl = computed(() => props.currentUser?.logo || logoPlaceholder)

const navigation = [
  { name: 'Pulpit', href: '/', icon: HomeIcon },
  { name: 'Zespół', href: '/zespol', icon: UsersIcon },
  { name: 'Oferty', href: '/oferty', icon: FolderIcon },
  { name: 'Kontrahenci', href: '/klienci', icon: CalendarIcon },
  { name: 'Produkty', href: '/produkty', icon: DocumentDuplicateIcon },
  { name: 'AI Asystent', href: '/ai_assistant', icon: ChatBubbleLeftRightIcon },
  { name: 'Ustawienia', href: '/ustawienia', icon: ChartPieIcon },
]

const sidebarOpen = ref(false)

const logout = () => {
  router.delete('/users/sign_out', {
    preserveScroll: true
  })
}
</script>

<script lang="ts">
export default {
  name: 'MainLayout'
}
</script>
